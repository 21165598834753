.table {
  @apply table-auto border-spacing-0 border-separate;
  
  th {
    @apply border-b border-primary-main
  }

  thead {
    @apply bg-white-600 capitalize text-white-0 sticky top-0 z-20;

    tr {
      th {
        @apply z-10 bg-white-600 p-3;
      }
    }
  }


  tbody {
    tr {
      td {
        @apply max-w-xs truncate px-3 py-[13px];

        picture {
          @apply flex items-center justify-center;
        }
      }
    }
  }
}

.tablev2 {
  @apply table-auto;

  thead {
    @apply  bg-white-600 capitalize text-white-0;

    tr {
      th {
        @apply z-10 bg-white-600 p-3;
      }
    }
  }

  tbody,
  tfoot {
    tr {
      td {
        @apply px-3 py-[13px];

        picture {
          @apply flex items-center justify-center;
        }
      }
    }
  }
}

.paginationActive {
  @apply rounded-md bg-primary-hover;
}